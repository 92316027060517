import React from 'react';
import ornelix_logo from './ornelix_logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={ornelix_logo} className="App-logo" alt="ornelix_logo" />
        <p>
          Coming (very) soon..
        </p>
        <a
          className="App-link"
          href="https://google-federated.auth.us-east-1.amazoncognito.com/login?client_id=utr1ghe7n61fg4a2v88is7m0i&response_type=code&scope=email+openid+phone&redirect_uri=https://ornelix.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ornelix Login
        </a>
      </header>
    </div>
  );
}

export default App;
